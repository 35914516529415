<template>
  <div class="showcase">
    <div class="header-box">
      <div>
        <h2 class="mb-4 mb-md-11">{{ $t("faq") }}</h2>
        <v-text-field
          v-model="criteria"
          style="width: 70%"
          solo
          flat
          :placeholder="$t('search')"
          rounded
          hide-details
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>
    </div>
    <v-container>
      <v-row class="mt-6 mt-md-12">
        <v-col cols="12" md="3">
          <div class="question pb-2 pb-md-16">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(questionHeader, index) in questionSections"
                :key="index"
                class="question-box"
              >
                <v-expansion-panel-header class="question-header">
                  {{ questionHeader[0].title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul class="question-list">
                    <li v-for="question in questionHeader" :key="question.id">
                      <a
                        @click.prevent="
                          scrollMeTo(question.question.split(' ').join(''))
                        "
                        >{{ question.question }}</a
                      >
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-col cols="12" md="9" class="pl-3 pl-md-16 mb-6 md-12">
          <div v-for="question in questions" :key="question.id">
            <v-card
              v-if="!question.title"
              :ref="question.question.split(' ').join('')"
              elevation="2"
              class="card mb-7"
            >
              <v-card-title class="card-title">
                ● {{ $t("question") }}: &nbsp;
                <span v-html="question.question"></span>
              </v-card-title>

              <v-card-text class="card-text pa-4">
                <b>{{ $t("answer") }}: </b>
                <span v-html="question.answer"> </span>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-btn @click="goUp" color="#fff" class="go-up-btn" elevation="3" fab>
      <v-icon color="#7566fd" size="40">mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import API from "../../services/_app.service.js";

export default {
  data() {
    return {
      panel: [0],
      questionSections: [],
      questions: [],
      criteria: "",
      lang: this.$i18n.locale || localStorage.getItem("lang") || "en",
    };
  },
  watch: {
    "$i18n.locale": function (lang) {
      this.getFaqQuestions(lang);
    },
    criteria(text) {
      this.getFaqQuestions(this.lang, text);
    },
  },
  methods: {
    async getFaqQuestions(lang, criteria = null) {
      const { data } = await API(
        `/faqs/find-all?lang=${lang}&limit=300&offset=0${
          criteria ? "&criteria=" + criteria : ""
        }`
      );
      this.questions = data;
      if (!this.questionSections.length || this.lang !== lang) {
        this.questionSections = [];
        const idx = [];
        data.forEach((item, index) => item.title && idx.push(index));
        for (let i = 0; i < idx.length; i++) {
          this.questionSections.push(data.slice(idx[i], idx[i + 1]));
        }
      }
      this.lang = lang;
    },
    scrollMeTo(refName) {
      this.criteria = null;
      this.getFaqQuestions(this.lang, null).then(() => {
        const top = this.$refs[refName][0].$el.offsetTop;
        window.scrollTo(0, top);
      });
    },
    goUp() {
      window.scrollTo(0, 0);
    },
    onScroll() {
      const goUpBtn = document.querySelector(".go-up-btn");
      if (
        document.body.scrollTop > 350 ||
        document.documentElement.scrollTop > 350
      ) {
        goUpBtn.style.display = "block";
      } else {
        goUpBtn.style.display = "none";
      }
    },
  },
  mounted() {
    this.getFaqQuestions(this.lang);
    window.onscroll = () => {
      this.onScroll();
    };
  },
  destroyed() {
    window.onscroll = null;
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
