import axios from "axios";

const APP_BASE_URL = "https://back.appfeedy.com/api";

const axiosInstance = axios.create({
  baseURL: APP_BASE_URL,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
});

export default axiosInstance;
